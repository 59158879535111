import Api from "@/Api/Api";
import ServerResponse from "@/Api/Server/Response/ServerResponse";
import ServerProviderResponse from "@/Api/Server/Response/ServerProviderResponse";
import ServerCreateRequest from "@/Api/Server/Request/ServerCreateRequest";
import ServerFeatureRequest from "@/Api/Server/Request/ServerFeatureRequest";
import PaginateObject from "@/Api/PaginateObject";

class ServerApi extends Api {
    list(paginate: PaginateObject|null = null): Promise<any> {
        let parameters: string = '';
        if (paginate !== null) {
            parameters = '?';
            // @ts-ignore
            parameters += new URLSearchParams(paginate).toString();
        }

        return this.get<ServerResponse[]>("/servers" + parameters);
    }

    create(data: ServerCreateRequest): Promise<any> {
        return this.post<ServerCreateRequest>("/servers", data);
    }

    getServer(serverId: string): Promise<any> {
        return this.get<ServerResponse>("/servers/" + serverId);
    }

    providers(): Promise<any> {
        return this.get<ServerProviderResponse[]>("/servers/providers");
    }

    setDefaultPHP(serverId: string, version: string): Promise<any> {
        const data: any = [];
        return this.patch<ServerResponse>("/servers/" + serverId + "/default_php/" + version, data);
    }

    installFeature(serverId: string, feature: ServerFeatureRequest): Promise<any> {
        return this.post<ServerFeatureRequest>("/servers/" + serverId + "/features", feature);
    }

    uninstallFeature(serverId: string, featureId: string): Promise<any> {
        return this.delete<ServerFeatureRequest>("/servers/" + serverId + "/features/" + featureId);
    }
}

export default new ServerApi();
