







































































import {Component, Vue, Watch} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import ServerStore from "@/Store/Modules/ServerStore";
import ServerApi from "@/Api/Server/ServerApi";
import ServerResponse from "@/Api/Server/Response/ServerResponse";
import ServerFeatureResponse from "@/Api/Server/Response/ServerFeatureResponse";
import {ValidationObserver} from "vee-validate";
import INameValue from "@/Api/Interfaces/INameValue";

@Component({
    components: {
        // @ts-ignore
        'confirm-dialog': () => import(/* webpackChunkName: "confirm-dialog" */ '@/Components/ConfirmDialog')
    }
})
export default class ServerIndex extends Vue {
    $refs!: {
        form: InstanceType<typeof ValidationObserver>;
    };
    menuStore: MenuStore = getModule(MenuStore);
    serverStore: ServerStore = getModule(ServerStore);
    deleteDialog: boolean = false;
    defaultDialog: boolean = false;
    pendingAction: boolean = false;
    installValid: boolean = false;
    selectedPHPVersion: string = "";
    phpVersionAvailable: INameValue[]|undefined = [];
    installedPHP: ServerFeatureResponse[] = [];
    selectedFeature: ServerFeatureResponse|null = null;

    mounted() {
        this.getServerChanged();
    }

    get getServer(): ServerResponse|null {
        return this.serverStore.getServer;
    }

    @Watch('getServer')
    getServerChanged() {
        this.installedPHP = [];
        this.pendingAction = false;

        const serverFeatures = this.getServer?.features;
        this.phpVersionAvailable = this.getServer?.supported_php_versions;
        serverFeatures?.forEach((feature) => {
            if (feature.feature === "php") {
                if (feature.pending === true) {
                    this.pendingAction = true;
                }
                this.installedPHP.push(feature);
            }
        });

    }

    setAsDefaultButton(feature: ServerFeatureResponse) {
        this.selectedFeature = feature;
        this.defaultDialog = true;
    }

    setAsDefault(feature: ServerFeatureResponse) {
        this.defaultDialog = false;
        ServerApi.setDefaultPHP(this.$route.params['id'], feature.version as string).then((response) => {
            this.serverStore.setServer(response.data);
        });
    }

    uninstallFeatureButton(feature: ServerFeatureResponse) {
        this.selectedFeature = feature;
        this.deleteDialog = true;
    }

    uninstallFeature(feature: ServerFeatureResponse) {
        this.deleteDialog = false;
        ServerApi.uninstallFeature(this.$route.params['id'], feature.id as string).then((response) => {
            this.serverStore.setServer(response.data);
        });
    }

    installPHP() {
        this.pendingAction = true;
        this.$refs.form.validate().then((valid) => {
            if (!valid) {
                this.pendingAction = false;
                return;
            }

            ServerApi.installFeature(this.$route.params['id'], { feature: "php", version: this.selectedPHPVersion})
                .then((response) => {
                    this.serverStore.setServer(response.data);
                    this.selectedPHPVersion = "";
                    this.$refs.form.reset();
                })
                .catch((error: any) => {
                    this.pendingAction = false;
                    this.$refs.form.setErrors(error.response.data.errors);
                });
        });
    }
}
